import React from "react"
import { Link } from "gatsby"

function Button(props) {
  return (
    <div className="buttonDetail">
      <p className="buttonTitle">{props.buttonTitle}</p>
      <p>{props.buttonSubtext}</p>
      <Link to={props.buttonLink}>
        {props.buttonText}
      </Link>
    </div>
  )
}

export default Button

import React, { Fragment } from "react"
import Helmet from "react-helmet"
import { withPrefix } from "gatsby"
import "../components/styles.scss"
import Header from "../components/header"
import Footer from "../components/footer"
import Button from "../components/button"
import Image from "../components/image"
import "../../static/script.js"

export default function Home() {
  return (
    <>
      <Helmet>
        <script src={withPrefix("script.js")} type="text/javascript" />
      </Helmet>
      <div>
        <Header />
        <div id="redDiag_1"></div>
        <div id="redDiag_2"></div>
        <section className="hero">
          <div className="container">
            <div className="overflow">
              <Image
                src="online-elearning-course-developers-2.jpg"
                alt="PFSA Football Elearning Course"
                className="img1"
              />
            </div>
            <div className="content">
              <h2 className="title">We Create Digital Learning</h2>
              <ul className="contactList">
                <li>01603 273918</li>
                <li>
                  <a href="mailto:hello@realprojects.co.uk">
                    hello@realprojects.co.uk
                  </a>
                </li>
              </ul>
            </div>
            <Button
              buttonTitle="Custom Elearning Design"
              buttonSubtext="PFSA Football Elerning Courses"
              buttonLink="PFSA-Technical-Scouting-Level-1-Product-Sheet.pdf"
              buttonText="See what we did"
            />
          </div>
        </section>
        <section>
          <div className="container">
            <div className="overflow">
              <Image
                src="elearning-developers2.jpg"
                className="img2"
                alt="User Taking Online Elearning Course"
              />
              {/* <div class="redOverlay"></div> */}
            </div>
            <div className="content">
              <h2 className="title">Elearning Developers</h2>
              <p>
                <strong>
                  We design innovative learning technology solutions and support
                  our clients with their learning and development projects.
                </strong>
              </p>
              <p>
                Our team can create or re-use content to create a custom
                solution for your organisation.
              </p>
              <a className="button" href="We-Are-Real-Projects.pdf">
                See what we did
              </a>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="overflow">
              <div className="courses">
                <ul className="courseList">
                  <li>Remote Challenges: Social Isolation </li>
                  <li>
                    Security for Retail and Hospitality: Staff Desk and Counter
                    Security
                  </li>
                  <li>
                    COVID-19 Customer Service: De-escalating Mask-related
                    Conflict
                  </li>
                  <li>Digital Security While Working from Home</li>
                  <li>Understanding Workplace Conflict</li>
                  <li>Why Projects Fail</li>
                  <li>Writing Quickly and Effectively</li>
                  <li>Continuity Planning: Managing Talent</li>
                </ul>
                <a
                  className="button"
                  href="Real-Projects-Elearning Course-Library.pdf"
                >
                  View all{" "}
                </a>
              </div>
            </div>
            <div className="content">
              <h2 className="title">Off the shelf Courses</h2>
              <p style={{ fontWeight: 900 }}>
                Interactive courses that provide cutting-edge training resources
                for organizations.
              </p>
              <p>
                Real Projects are expert course creators, with 15 years'
                experience delivering innovative elearning. Ou focus is on
                creative content and rewarding learner journey.
              </p>
            </div>
          </div>
        </section>
        <section id="content1" className="extraContent active">
          <div className="container">
            <div className="overflow">
              <Image
                src="online-elearning-support.jpg"
                className="img2"
                alt="User Taking Online Elearning Course"
              />
              {/* <div class="redOverlay"></div> */}
            </div>
            <div className="content">
              <h2 className="title">ONGOING SUPPORT</h2>
              <p style={{ fontWeight: 900 }}>
                If you have a project that needs support, no matter what stage,
                get in touch.
              </p>
              <p>
                We've helped clients with lost, corrupt and outdated project
                files. We can also support you after development has finished.
              </p>
            </div>
          </div>
        </section>
        {/* <section id="content2" className="extraContent">
          <div className="container">
            <div className="overflow">
              <img className="img2" src="http://placekitten.com/600/600" />
            </div>
            <div className="content">
              <h2 className="title">Content Title 2</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Distinctio voluptate ex fugit accusantium nobis, inventore
                recusandae quam facere sint quaerat harum quia quos nisi eum
                unde illo odit suscipit necessitatibus!
              </p>
            </div>
          </div>
        </section> */}
        <Footer />
      </div>
    </>
  )
}

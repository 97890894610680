import React from "react"
import Social from "../components/social"
import Image from "../components/image"
import { Link } from "gatsby"

const Header = ({ siteTitle }) => (
  <React.Fragment>
    <header>
      <div className="container">
        <Social />
        <div className="menu">
          <div><Link to="#contactForm">Contact</Link></div>
          <div className="logo">
            <Image
              src="real-projects-logo-black.png"
              alt="Real Projects Logo"
              style={{ width: "160px" }}
            />
          </div>
        </div>
      </div>
    </header>
  </React.Fragment >
)

export default Header

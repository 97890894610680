import React from "react"

const Contact = () => {
    return (
        <form id="contactForm" action="https://getform.io/f/e85ff9e0-63f8-4887-87e6-a8ed1e9c76eb" method="POST">
            <label>
                Name
                <input type="text" name="name" id="name" />
            </label>
            <label>
                Email address
                <input type="email" name="email" id="email" />
            </label>
            <label>
                Phone number
                <input type="text" name="phone" id="phone" />
            </label>
            <label>
                What can we help you with?
                <textarea name="message" id="message" rows="5" />
            </label>
            <button type="submit">Submit</button>
        </form>
    )
}

export default Contact;